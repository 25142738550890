<template>
  <div>
    <div class="" style="width: 100%">
      <img style="display: block;width: 1920px;margin: auto;" src="../../assets/images/djt/bigdata.jpg" alt="">
    </div>
    <div class="bg">
      <div class="wrapper">
        <div>
          <div _ngcontent-c6="" class="page1">
            <h2 _ngcontent-c2="" class="center">大数据（数据安全）</h2>
            <p _ngcontent-c2="" class="em2">大数据是海量数据存储和处理的技术，大数据技术基于分布式的架构，实现了近乎无限的存储和处理的能力，为海量数据的存储和处理提供了新的可能。而正是基于这样的能力，越来越多的基于大数据的精准、个性化的服务开始大量的出现，可以说大数据已经彻底改变了人们的生活。</p>
            <div _ngcontent-c2="" class="img"><img _ngcontent-c2="" alt="" src="../../assets/images/djt/dsj6.png"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</template>

<style lang="less" scoped>
  .bold {
    font-weight: bold;
  }
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2{
  text-indent: 2em;
  line-height: 40px;
}
.center{
  text-align: center;
}
.img {
  text-align: center;
  img {
    width: 100%;
  }
  // width: 100%;
  // display: flex;
  // padding: 10px 0;
  // align-items: center;
  // justify-content: center;
}
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
